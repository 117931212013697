<template>
  <div>
       <nav-bar-back class="detail-nav">
    <div class="title" slot="title">
     <div slot="center">客服中心</div>
    </div>
  </nav-bar-back>
  <div class="des" v-html="content">

  </div>
    
  </div>
</template>

<script>

import NavBarBack from 'common/navbar/NavBarBack'
import {article} from "network/others";
export default {
  components: {
      NavBarBack,
      
  },
  data () {
      return {
      content:''      
      }
  },
  created () {
      article(2).then(res=>{
          this.content=res.content
      })
  }
}
</script>

<style scoped>
  .des{
      margin: 20px
  }
</style>